import React, {useEffect, useState} from "react";
import {navigate} from "gatsby";

import Navbar from "../../../components/navbar";
import Layout from "../../../components/layout";

import * as Services from '../../../services'
import productor from "../../../images/productor.svg";
import Seo from "../../../components/seo";

function ReportsPage() {
    const [productores, setProductores] = useState([])
    useEffect(() => {
        Services.Productores().then((response) => {
            setProductores(response.productores)
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const goToURL = (url, productor) => {
        localStorage.setItem('productor', JSON.stringify(productor));
        navigate(url);
    }
    return(
        <Layout>
            <Navbar />
            <div className="dashInfo">
                <div className="titleGeneral h-1/4">
                    <div className="font-bold text-white text-center pt-8 pb-12">
                        Descarga de Reportes
                    </div>
                </div>
                <div className="bg-white pt-6 px-6 overflow-y-auto h-3/4 rounded-t-3xl -mt-8">
                    {
                        productores && productores.map((productorItem) => (
                            <button className="card mb-4 bg-yellow-300" onClick={() => goToURL('productor', productorItem)}>
                                <img src={productor} alt="productor" width={80} height={80} className="mr-4" />
                                <div className="font-bold font-greenup-body text-green-gu text-center">
                                    { productorItem.name } <br /> { productorItem.razonSocial }
                                </div>
                            </button>
                        ))
                    }
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title="Reportes" />

export default ReportsPage
